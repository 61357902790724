/* Global styles */
.awssld__bullets {
  display: none!important;
}

.pswp__img {
  object-fit: contain !important;
  transition: all .3s;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c8baba;
  margin: 0 1px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Mobile view */
@media (max-width: 768px) {
  .awssld__wrapper {
    height: auto!important;
  }
}